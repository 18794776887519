import OrganizationProfileForm from "@earnnest-e2-frontend/platform-views/src/mantine/OrganizationProfileForm"
import { Box, Space, Text, Title } from "@mantine/core"
import { useHistory } from "react-router-dom"

export default function BusinessProfileScreen() {
  const history = useHistory()
  return (
    <Box>
      <Space h="xl" />
      <Title size="h2" order={2}>
        Enroll your business
      </Title>
      <Space h="xs" />
      <Text size="lg">
        Tell us about your business. We'll use this information during our
        verification process.
      </Text>
      <Space h="xl" />
      <OrganizationProfileForm
        onSubmitSuccess={(organization) => {
          history.replace({ pathname: `/${organization.slug}/branding` })
          // window.location.replace(
          //   `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${organization.slug}/home`,
          // )
        }}
      />
    </Box>
  )
}
