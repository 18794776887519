import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import { PortalLayout } from "@earnnest-e2-frontend/platform-ui/src/mantine"
import { LoadingOverlay } from "@mantine/core"
import { useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import AccountTypeSelectionForm from "./forms/AccountTypeSelectionForm"
import BusinessBrandingScreen from "./forms/BusinessBrandingScreen"
import BusinessEscrowLandingScreen from "./forms/BusinessEscrowLandingScreen"
import BusinessFrontMatterScreen from "./forms/BusinessFrontMatterScreen"
import BusinessProfileScreen from "./forms/BusinessProfileScreen"
import IndividualFrontMatterScreen from "./forms/IndividualFrontMatterScreen"
import CallbackPage from "./pages/CallbackPage"
import ErrorPage from "./pages/ErrorPage"
import LoginPage from "./pages/LoginPage"
import LogoutPage from "./pages/LogoutPage"

export default function App() {
  const [backdropProps, setBackdropProps] = useState({
    backdrop: undefined,
    backdropColor: undefined,
  })

  const { isLoading, isAuthenticated } = useEarnnestAuth0()

  if (isLoading) {
    // TODO: style this in theme
    return <LoadingOverlay visible />
  }

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route>
          <LoginPage />
        </Route>
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/logout">
        <LogoutPage />
      </Route>
      <Route path="/callback">
        <CallbackPage />
      </Route>
      <Route>
        <PortalLayout
          panelOpened={true}
          backdrop={backdropProps.backdrop}
          backdropColor={backdropProps.backdropColor}>
          <Switch>
            <Redirect exact from="/" to="/role" />
            <Route exact path="/role">
              <AccountTypeSelectionForm />
            </Route>
            <Route exact path="/agent/register">
              <IndividualFrontMatterScreen />
            </Route>
            <Route exact path="/business/enroll">
              <BusinessFrontMatterScreen />
            </Route>
            <Route exact path="/business/profile">
              <BusinessProfileScreen />
            </Route>
            <Route exact path="/:orgSlug/branding">
              <BusinessBrandingScreen setBackdropProps={setBackdropProps} />
            </Route>
            <Route exact path="/:orgSlug/escrow">
              <BusinessEscrowLandingScreen />
            </Route>
            <Route path="*">
              <ErrorPage
                errorCode="404"
                errorMessage="This is not a valid url."
              />
            </Route>
          </Switch>
        </PortalLayout>
      </Route>
    </Switch>
  )
}
